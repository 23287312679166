import { Typography, styled } from "@mui/material";


const fontMain = '#FBF8F3'
const bgMain = '#828F7B'
const cursive = "'Great Vibes', cursive"
export const HeaderWrapper = styled('div', {
  shouldForwardProp: (prop) => true
})`
  background-color: ${bgMain};
  display: flex;
  min-height: 100vh;
  width: 100vw;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
`

export const HeroCursive = styled(Typography, {
  shouldForwardProp: (prop) => true
})`
  color: ${fontMain};
  font-family: ${cursive};
  font-size: 2rem;
`

export const RowContent = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const HeroText = styled(HeroCursive, {
  shouldForwardProp: (prop) => true
})`
  font-size: 12vw;
`

export const HeroSmallText = styled(Typography, {
  shouldForwardProp: (prop) => true
})`
  font-family: 'Times';
  color: ${fontMain};
`
export const HeroPreText = styled(HeroSmallText, {
  shouldForwardProp: (prop) => true
})`
  color: ${fontMain};
  font-size: 4vw;
`

export const HeroSubText = styled(HeroSmallText, {
  shouldForwardProp: (prop) => true
})`
  font-size: 1.2rem;
`

export const HeroTitleWrapper = styled('div')`
  display: flex;
  flex: 1;
  max-width: 700px;

`

export const June30Mask = styled('div')`
  -webkit-mask-image: url(june30_mask.png);
  mask-image: url(june30_mask.png);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-size: contain;
  flex: 1;
`

export const Image = styled('img')`
  flex: 1;
  height: 100%
`
export const ADMask = styled('div')`
  -webkit-mask-image: url(ar_da_names.png);
  mask-image: url(ar_da_names.png);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-size: contain;
  flex: 1;
  max-width: 300px;
`
