import { FC } from "react";
import { ADMask, HeaderWrapper, HeroCursive, HeroPreText, HeroSubText, HeroText, HeroTitleWrapper, Image, June30Mask, RowContent } from "./styles";

export const Header: FC = ({}) => {
  return (
    <HeaderWrapper>
      <RowContent>
        <HeroSubText><br /></HeroSubText>
        
        {/* <HeroTitleWrapper>
          <June30Mask>
            <Image src='floret_bg.jpg' alt='June 30th, 2024' width='100%' height='100%' />
          </June30Mask>
        </HeroTitleWrapper> */}
      </RowContent>
      <RowContent>
        <HeroSubText sx={{ marginTop: 4 }}>Join us in the afternoon of</HeroSubText>

        <HeroText>June 30th, 2024</HeroText>
      </RowContent>
      <RowContent>
        <HeroSubText>for the wedding of</HeroSubText>
        <HeroCursive>Anna Raynor & Dillon Ashley</HeroCursive>
        {/* <ADMask>
          <Image src='floret_bg.jpg' alt='Anna Raynor & Dillon Ashley' width='100%' height='100%' />
        </ADMask> */}
      </RowContent>
      {/* <RowContent>
        <HeroPreText>The Wedding of Anna Raynor & Dillon Ashley</HeroPreText>
      </RowContent> */}
      <RowContent>
        <HeroSubText sx={{ fontWeight: 'bold' }}>The Brazilian Room</HeroSubText>
        <HeroSubText>Wildcat Canyon Road</HeroSubText>
        <HeroSubText> Berkeley, CA 94708</HeroSubText>
      </RowContent>
      <RowContent>
        <HeroCursive>More info to come soon...</HeroCursive>
      </RowContent>
    </HeaderWrapper>
  );
};
